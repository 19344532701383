<template>
    <div>
        <jy-query labelWidth="100" class="query_area" size="small">
            <jy-query-item label="地磅ID">
                <el-input placeholder="请输入地磅ID" v-model="form.wbdId"></el-input>
            </jy-query-item>
            <jy-query-item label="地磅名称">
                <el-input placeholder="请输入地磅名称" v-model="form.cName"></el-input>
            </jy-query-item>
            <jy-query-item label="分拣中心">
                <el-select v-model="form.unitId">
                    <el-option v-for="u in unitList" :key="u.unitId" :value="u.unitId" :label="u.cname"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="所属机构">
                <div @click="selectInstitutions">
                    <el-input placeholder="请选择" v-model="form.orgNa"></el-input>
                </div>
            </jy-query-item>
            <template slot="rightCol">
                <el-button size="small" type="primary" @click="query"
                    v-if="btnexist('wastesBasicInfoWeighbridgeList')"
                >查询</el-button>
                <el-button size="small" type="primary" @click="reset"
                    v-if="btnexist('wastesBasicInfoWeighbridgeReset')"
                >重置</el-button>
                <el-button size="small" type="primary" @click="add"
                    v-if="btnexist('wastesBasicInfoWeighbridgeInsert')"
                >新增</el-button>
            </template>
        </jy-query>

        <div class="jy-table" v-loading="loading">
            <jy-table :data="dataList">
                <jy-table-column type="index" label="序号" width="60">
                </jy-table-column>
                <jy-table-column label="地磅ID" prop="wbdId">
                </jy-table-column>
                <jy-table-column label="地磅名称" prop="cName">
                </jy-table-column>
                <jy-table-column label="地磅位置" prop="addr">
                </jy-table-column>
                <jy-table-column label="地磅预警值(kg)" prop="warningW">
                </jy-table-column>
                <jy-table-column label="分拣中心">
                    <template v-slot="scope">{{ getNameByUnitId(scope.row.unitId) }}</template>
                </jy-table-column>
                <jy-table-column label="所属机构">
                    <template v-slot="scope">{{ scope.row.org.orgNa }}</template>
                </jy-table-column>
                <jy-operate :list="operationList" width="200"></jy-operate>
            </jy-table>
        </div>
        <jy-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageIndex"
                :page-size="pageSize"
                :total="total"
            ></jy-pagination>
         <institutions-tree refresh :showType="[0, 1, 2]" ref="institutions" @addInstitutions="addInstitutions" title="选择所属机构"></institutions-tree>
         <add ref="add" @update="query" />
    </div>
</template>

<script>
import { btnMixins } from "@/common/js/button.mixin";
import add from '@/components/pages/admin/basic/weighbridge/add.vue';
import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";

export default {
    mixins: [btnMixins],

    components: {
        add,
        institutionsTree
    },

    data() {
        return {
            btnMenuId: "wastesBasicInfoWeighbridge",
            pageIndex: 1,
            pageSize: 10,
            total: 0,
            loading: false,
            operationList: [],
            unitList: [],
            dataList: [],
            form: {
                unitId: null,
                orgId: null,
                orgNa: '',
                cName: null,
                wbdId: null
            }
        }
    },

    created() {
        this.setOprationList();
        this.getList();
        this.getUnitList();
    },

    methods: {
        query() {
            this.getList();
        },

        reset() {
            Object.keys(this.form).forEach(each => {
                this.form[each] = null;
            })
            this.getList();
        },

        add() {
            this.$refs.add.init({
                title: '新增地磅',
                type: 0,
            });
        },

        getNameByUnitId(unitId) {
            const res = this.unitList.find(each => each.unitId === unitId) || {};
            return res.cname || '-';
        },

        getList() {
            this.loading = true;
            const { pageIndex, pageSize, form } = this
            this.$http
                .post('/base/weighbridge/pageList', {
                    pageIndex,
                    pageSize,
                    ...form
                })
                .then(res => {
                    this.dataList = res.detail.list;
                    this.loading = false
                })
        },

        getUnitList() {
            this.$http.post("/unitInfo/queryUnitList", {
                    cName: "",
                    unitType: "3"
                })
                .then(res => {
                    this.unitList = res.detail
                })
        },

        addInstitutions({ orgId, orgNa }) {
                this.form.orgId = orgId
                this.form.orgNa = orgNa
        },

        selectInstitutions() {
                this.$refs.institutions.init();
        },

        handleSizeChange(pageSize) {
            this.pageSize = pageSize
        },

        handleCurrentChange(pageIndex) {
            this.pageIndex = pageIndex
        },

        onEdit(row) {
            this.$refs.add.init({
                title: '修改地磅',
                type: 1,
                form: {
                    ...row,
                    ...row.org
                }
            })
        },

        onDelete(row) {
            this.$http.post('/base/weighbridge/delete', row.wbId)
                .then(() => {
                    this.query();
                    this.$message.success({
                        message: '成功',
                        duration: 1500
                    });
                })
        },

        setOprationList() {
            this.operationList = [
                {
                    name: "编辑",
                    icon: "el-icon-edit-outline",
                    fun: this.onEdit,
                    isShow: () => {
                        return this.btnexist("wastesBasicInfoWeighbridgeUpdate");
                    }
                },
                {
                    name: "删除",
                    icon: "el-icon-edit-outline",
                    needConfirm: true,
                    title: '是否删除该地磅?',
                    fun: this.onDelete,
                    isShow: () => {
                        return this.btnexist("wastesBasicInfoWeighbridgeDelete");
                    }
                }
            ]
        }
    }
}
</script>