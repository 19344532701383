<template>
    <div>
        <jy-dialog type="form" custom-class="dialog_form" :visible.sync="visible" :title="title" @close="close"
            :width="global.dialogWidth">
            <div>
                <el-form label-width="120px" :rules="rules" ref="_form" :model="form">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="地磅ID" prop="wbdId">
                                <el-input v-model="form.wbdId"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="地磅名称" prop="cName">
                                <el-input v-model="form.cName"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="所属机构" prop="orgId">
                                <div @click="selectInstitutions">
                                    <el-input placeholder="请选择" v-model="form.orgNa"></el-input>
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="分拣中心" prop="unitId">
                                <el-select v-model="form.unitId">
                                    <el-option v-for="u in $parent.unitList" :key="u.unitId" :value="u.unitId"
                                        :label="u.cname"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <el-form-item label="地磅位置" prop="addr">
                                <div @click="selectAddress">
                                    <el-input placeholder="请选择" :value="form.addr"></el-input>
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="地磅预警值(kg)">
                                <el-input v-model="form.warningW"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="confirm">确 定</el-button>
            </div>
        </jy-dialog>
        <institutions-tree refresh :showType="[0, 1, 2]" ref="institutions" @addInstitutions="addInstitutions"
            title="选择所属机构"></institutions-tree>
        <select-addr ref="addr" :limit="1" @select="getAddr"></select-addr>
    </div>
</template>

<script>
import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";
import SelectAddr from "@/components/pages/admin/map/SelectAddr.vue";

export default {
    components: {
        institutionsTree,
        SelectAddr
    },

    data() {
        return {
            title: '',
            visible: false,
            form: {
                wbdId: null,
                cName: null,
                orgId: null,
                orgNa: null,
                unitId: null,
                lng: null,
                lat: null,
                addr: null,
                warningW: null
            },
            formBak: null,

            rules: {
                wbdId: [{ required: true, message: "地磅ID不能为空", trigger: "change" }],
                cName: [{ required: true, message: "地磅名称不能为空", trigger: "change" }],
                orgId: [{ required: true, message: "所属机构不能为空", trigger: "change" }],
                unitId: [{ required: true, message: "分拣中心不能为空", trigger: "change" }],
            },
        }
    },

    methods: {
        init(options = {}) {
            if (!this.formBak) {
                this.formBak = JSON.parse(JSON.stringify(this.form));
            }
            if (options.form) {
                this.form = options.form;
            }
            this.title = options.title || '';
            this.type = options.type || 0;
            this.visible = true;
        },

        reset() {
            this.form = JSON.parse(JSON.stringify(this.formBak))
        },

        cancel() {
            this.visible = false;
        },

        add() {
            this.$http
                .post('/base/weighbridge/add', {
                    ...this.form
                })
                .then(() => {
                    this.visible = false;
                    this.$emit('update')
                    this.$message.success({
                        message: '成功',
                        duration: 1500
                    })
                })
        },

        update() {
            this.$http
                .post('/base/weighbridge/update', {
                    ...this.form
                })
                .then(() => {
                    this.visible = false;
                    this.$emit('update')
                    this.$message.success({
                        message: '成功',
                        duration: 1500
                    })
                })
        },

        confirm() {
            this.$refs._form.validate((valid) => {
                if (valid) {
                    if (this.type === 0) {
                        this.add();
                    } else if (this.type === 1) {
                        this.update();
                    }
                }
            })
        },

        addInstitutions({ orgId, orgNa }) {
            this.form.orgId = orgId;
            this.form.orgNa = orgNa;
        },

        selectInstitutions() {
            this.$refs.institutions.init();
        },

        selectAddress() {
            this.$refs.addr.init();
        },

        getAddr({ lng, lat, addr }) {
            this.form.lng = lng;
            this.form.lat = lat;
            this.form.addr = addr;
        },

        close() {
            this.reset();
            this.$refs._form.resetFields();
            this.visible = false;
        }
    }
}
</script>

<style>
</style>